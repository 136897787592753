import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Companies from "../components/Companies/Companies"
import Pricing from "../components/PricingTable/PricingTable"

const PricingPage = () => (
  <Layout>
    <Seo title="Pricing" />
    <Pricing />
    <Companies />
  </Layout>
)

export default PricingPage
