/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { CheckCircleIcon } from "@heroicons/react/solid"
import AddToSlackButton from "../buttons/AddToSlack"

const includedFeatures = [
  "Works with AWS (more coming soon)",
  "Daily total cost & detailed analysis",
  "Intelligent spike alerts 🚨",
  "Insanely fast support 🤩",
  "Select your Slack channel & time",
  "Securely collaborate with your team",
]

export default function Pricing() {
  return (
    <div className="bg-gray-50">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">
              Simple pricing
            </h2>
            <p className="mt-4 text-xl font-medium text-gray-600">
              Try for free with a{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 to-blue-500">
                14 day account trial
              </span>
              . No credit card required.
            </p>
            <div className="mt-6">
              <AddToSlackButton />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 bg-gray-50 pb-16  sm:pb-20 lg:pb-28">
        <div className="relative">
          <div className="absolute inset-0 h-1/2" />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-lg bg-white mx-auto rounded-lg shadow-2xl overflow-hidden lg:max-w-5xl lg:flex">
              <div className="flex-1 px-6 py-8 lg:p-12">
                <h3 className="text-center text-xl font-bold text-gray-800 sm:text-2xl">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-indigo-600 text-5xl">
                    $10
                  </span>{" "}
                  per cloud account per month
                </h3>
                <h3 className="pt-4 text-center font-semibold text-md text-gray-700 sm:text-xl">
                  or pay{" "}
                  <span className="text-transparent font-bold bg-clip-text bg-gradient-to-r from-blue-500 to-indigo-600">
                    $100
                  </span>{" "}
                  annually
                </h3>
                <div className="mt-8">
                  <div className="flex items-center">
                    <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600">
                      What's included
                    </h4>
                    <div className="flex-1 border-t-2 border-gray-200" />
                  </div>
                  <ul
                    role="list"
                    className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:gap-y-5"
                  >
                    {includedFeatures.map(feature => (
                      <li
                        key={feature}
                        className="flex items-start lg:col-span-1"
                      >
                        <div className="flex-shrink-0">
                          <CheckCircleIcon
                            className="h-5 w-5 text-green-400"
                            aria-hidden="true"
                          />
                        </div>
                        <p className="ml-3 text-sm text-gray-700">{feature}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
